import React from 'react'
import { withTranslation as translate } from 'react-i18next'
import withConfig from '../../../services/withConfig'
import Quote from '../../../components/Quote'
import FormGroup from '../../../components/FormGroup'
import FormDiv from '../../../components/FormDiv'
import ButtonPanel from '../../../components/ButtonPanel'
import ActivityIndicator from '../../../components/ActivityIndicator'
import LocationSelect from './LocationSelect'
import PromotionOrReferrerCode from './PromotionOrReferrerCode'
import Name from './Name'
import Mobile from './Mobile'
import Address from './Address'
import Birthdate from './Birthdate'
import Gender from './Gender'
import Email from './Email'
import Password from './Password'
import PaymentMethod from './PaymentMethod'
import Gdpr from './GDPR'
import CheckoutButton from './CheckoutButton'
import BlockedError from './BlockedError'
import AcceptTerms from './AcceptTerms'
import QuoteContext from '../../../components/Quote/context'
import Captcha from './Captcha'

const Form = (props) => {
  // State
  const {
    processing,
    operatorUUID,
    locationUUID,
    code,
    firstName, lastName,
    street, postalCode, city,
    mobilePrefix, mobileNumber,
    birthdateYear, birthdateMonth, birthdateDay,
    gender,
    email, emailConfirmation,
    password, passwordConfirmation,
    paymentMethods, paymentMethod, gdprOptIn, acceptTerms,
    errors,
    requireVerification,
    config
  } = props

  // Event handlers
  const {
    t, onLocationChange, onCodeChange, onPhoneChange, onCaptchaDone, onInputChange, onSubmit
  } = props

  if (processing) {
    return (
      <div className='content processing'>
        <section className='main'>
          <ActivityIndicator i18nKey='signup.profile.validating' />
        </section>
      </div>
    )
  }

  const status = new URLSearchParams(window.location.search).get('status')
  const paymentDeclined = status === 'declined'
  const paymentError = status === 'error'

  return (
    <>
      <h1>{t('signup.profile.title')}</h1>

      {paymentDeclined &&
        <div className='paymentError'>{t('signup.payment.payment_declined')}</div>}
      {paymentError &&
        <div className='paymentError'>{t('signup.payment.payment_error')}</div>}

      <div className='signup-form-container'>
        <form className='signup-form' onSubmit={onSubmit} id='signup-form'>
          <main>
            <FormGroup title={t('signup.profile.club')} id='location'>
              <LocationSelect
                value={locationUUID}
                operatorUUID={operatorUUID}
                code={code}
                showRegions={props.config.showRegions}
                onChange={onLocationChange}
                errors={errors}
                inputRefs={props.inputRefs}
              />
            </FormGroup>
            <QuoteContext.Consumer>
              {quote => (
                <>
                  {quote.currentPayment && quote.nextPayment &&
                    <>
                      {quote.presentation &&
                        <section id='quote-presentation-mobile'>
                          <img src='/strong.svg' alt='' />
                          <p style={{ whiteSpace: 'pre-line' }}>
                            {quote.presentation}
                          </p>
                        </section>}
                    </>}
                </>
              )}
            </QuoteContext.Consumer>
            <FormGroup title={t('signup.profile.contact_details')} id='personalia'>
              <Name
                firstName={firstName}
                lastName={lastName}
                onChange={onInputChange}
                errors={errors}
                inputRefs={props.inputRefs}
              />
              <Address
                street={street}
                postalCode={postalCode}
                city={city}
                onChange={onInputChange}
                errors={errors}
                inputRefs={props.inputRefs}
              />
              <Mobile
                mobilePrefix={mobilePrefix}
                mobileNumber={mobileNumber}
                restricted={requireVerification}
                onChange={onPhoneChange}
                errors={errors}
                inputRefs={props.inputRefs}
              />
              <Birthdate
                year={birthdateYear || 1995}
                month={birthdateMonth || 1}
                day={birthdateDay || 1}
                onChange={onInputChange}
                errors={errors}
                inputRefs={props.inputRefs}
              />
              <Gender
                value={gender}
                onChange={onInputChange}
                errors={errors}
                inputRefs={props.inputRefs}
              />
            </FormGroup>
            <FormGroup title={t('signup.profile.login_details')} id='login-details'>
              <Email
                email={email}
                emailConfirmation={emailConfirmation}
                onChange={onInputChange}
                errors={errors}
                inputRefs={props.inputRefs}
              />
              <Password
                password={password}
                passwordConfirmation={passwordConfirmation}
                onChange={onInputChange}
                errors={errors}
                inputRefs={props.inputRefs}
              />
              <BlockedError errors={errors} t={t} />
            </FormGroup>
            {paymentMethods.length > 1
              ? (
                <FormGroup title={t('signup.profile.payment_method')} id='payment-method'>
                  <PaymentMethod value={paymentMethod} onChange={onInputChange} errors={errors} />
                </FormGroup>
                )
              : <input type='hidden' name='paymentMethod' value={paymentMethods[0]} />}
            <FormGroup title={t('signup.profile.gdpr.header')} id='gdpr'>
              <Gdpr
                value={gdprOptIn}
                onChange={onInputChange}
                errors={errors}
                inputRefs={props.inputRefs}
              />
            </FormGroup>
            <FormDiv title={t('signup.quote.product_code')} id='code'>
              <PromotionOrReferrerCode
                value={code}
                onChange={onCodeChange}
                errors={errors}
              />
            </FormDiv>
          </main>
          <aside>
            <Quote t={t} />
          </aside>
          <footer>
            <AcceptTerms
              onChange={onInputChange}
              value={acceptTerms}
              text={t('signup.profile.accepts_terms_html', { terms_url: config.termsURL, interpolation: { prefix: '%{', suffix: '}' } })}
              errors={errors}
              inputRefs={props.inputRefs}
            />
            {config?.friendlyCaptchaSitekey && <Captcha onDone={onCaptchaDone} />}
            <ButtonPanel>
              <CheckoutButton onSubmit={onSubmit} />
            </ButtonPanel>
          </footer>
        </form>
      </div>
    </>
  )
}

export default translate()(withConfig(Form))
