const operator = '5336003e-0105-4402-809f-93bf6498af34'

const stagingNorwayConfig = {
  operatorUUID: operator,
  theme: 'evo-norway',
  logo: 'logo.svg',
  currency: 'NOK',
  showRegions: true,
  paymentMethods: ['credit_card'],
  mobilePrefix: '+47',
  homeURL: 'https://evofitness.no',
  termsURL: 'https://evofitness.no/medlemsvilkar/',
  friendlyCaptchaSitekey: 'FCMQGBDD9FICCIF7',
  locales: {
    all: ['nb-NO'],
    defaultLocale: 'nb-NO'
  },
  tracking: {
    gtm: {
      id: undefined
    },
    adtraction: {
      c: undefined,
      tp: undefined,
      ap: undefined
    }
  },
  dibs: {
    callbackURL: `https://dibs-api.staging.credlock.net/${operator}/signup`,
    test: 'yes'
  },
  mypage: {
    url: 'https://mypage-ui-no.staging.credlock.net'
  }
}

export default stagingNorwayConfig
