// Norwegian design/language w/no tracking
const defaultConfig = {
  operatorUUID: '5336003e-0105-4402-809f-93bf6498af34',
  theme: 'evo-norway',
  logo: 'logo.svg',
  currency: 'NOK',
  showRegions: true,
  paymentMethods: ['credit_card', 'sepa'], // require 2 options for tests to run
  mobilePrefix: '+47',
  homeURL: 'https://evofitness.no',
  termsURL: 'https://evofitness.no/medlemskap/vilkaar',
  friendlyCaptchaSitekey: 'FCMQGBDD9FICCIF7',
  locales: {
    all: ['nb'],
    defaultLocale: 'nb'
  },
  tracking: {
    gtm: {
      id: undefined
    },
    adtraction: {
      c: undefined,
      tp: undefined,
      ap: undefined
    }
  },
  dibs: {
    callbackURL:
      'https://dibs-api.staging.credlock.net/5336003e-0105-4402-809f-93bf6498af34/signup',
    // callbackURL:
    // 'https://09473fcd2326.eu.ngrok.io/5336003e-0105-4402-809f-93bf6498af34/signup',
    test: 'yes'
  },
  mypage: {
    url: 'http://localhost:3003'
  }
}

export default defaultConfig
